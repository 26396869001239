<template>
  <div class='page-box'>
    <div class='intro-box'>
      <div class='h1'>说明</div>
      <div>
        <p>1、默认三个等级，可自定设定登记名称和条件</p>
        <p>2、所有刚加入的推客，默认为薪人推客，达到升级条件后可申请升级</p>
        <p>3、所有升级采取只升不降原则，期间允许修改升级条件，不影响推客原来的等级</p>
      </div>
    </div>
    <div style='margin-bottom:16px'>
      <a-button type='primary' @click='levelSetting'>级别设置</a-button>
    </div>
    <a-table :columns='columns' bordered :pagination="false" :rowKey="(record,index) => index" :dataSource="tableData" :loading="tableLoading">
      <template slot="level" slot-scope="text,record">{{record.level}}级</template>
    </a-table>

    <a-modal title='级别设置' width="680px" v-model='showModal' :confirm-loading='modalLoading' @ok='handleSubmit' @cancel='handleCancel'>
      <a-row type='flex' align='middle' justify='start' style='margin-bottom:12px;'>
        <a-col :span='3'>
          等级总数：
        </a-col>
        <a-col :span='8'>
          <a-input-number :max='10' :min="0" :precision='0' v-model="levelNum"></a-input-number> 级
        </a-col>
      </a-row>
      <a-row type='flex' align='top' justify='start'>
        <a-col :span='3'>
          <div style='line-height:54px'>等级设置：</div>
        </a-col>
        <a-col :span='21'>
          <a-table :columns="columns" :pagination="false" :rowKey="(record,index) => index" :dataSource="editTableData">
            <template slot="level" slot-scope="text,record">{{record.level}}级</template>
            <template slot="levelName" slot-scope="text,record,index">
              <a-input type='text' v-model="record.levelName" @change='checkNameInput($event,record,index)'></a-input>
              <p class='tip' v-if="record.nameErrorText">{{record.nameErrorText}}</p>
            </template>
            <template slot="levelAmount" slot-scope="text,record,index">
              <div v-if='record.level !== 1'>
                <a-input-number :min="0.01" :precision='2' v-model="record.levelAmount" @change='checkAmountInput($event,record,index)'></a-input-number>
                <p class='tip' v-if="record.amountErrorText">{{record.amountErrorText}}</p>
              </div>
              <div v-else>0</div>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { marketing } from '@/api'
import { onMounted, reactive, toRefs, watch } from '@vue/composition-api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
export default {
  name: 'PagePartner',
  setup (props, { root }) {
    const columns = [
      {
        title: '等级',
        dataIndex: 'level',
        scopedSlots: { customRender: 'level' },
      },
      {
        title: '等级名称',
        dataIndex: 'levelName',
        scopedSlots: { customRender: 'levelName' },
      },
      {
        title: '佣金下限（元）',
        dataIndex: 'levelAmount',
        scopedSlots: { customRender: 'levelAmount' },
      },
    ]
    const deepClone = (obj) => {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    const state = reactive({
      tableData: [],
      tableLoading: false,
      showModal: false,
      modalLoading: false,
      editTableData: [],
      levelNum: undefined,
    })
    onMounted(() => {
      getData()
    })
    watch(
      () => state.levelNum,
      (v) => {
        if (v > 0) {
          state.editTableData = deepClone(state.tableData)
          if (state.levelNum > state.tableData.length) {
            let diff = state.levelNum - state.tableData.length
            for (let i = 0; i < diff; i++) {
              state.editTableData.push({
                shopId: getSession(SHOPID),
                level: state.tableData.length + i + 1,
              })
            }
          } else {
            state.editTableData = state.editTableData.splice(0, v)
          }
          if (state.editTableData[0]) {
            state.editTableData[0].levelAmount = 0
          }
        } else {
          state.editTableData = []
        }
      },
      { immediate: true }
    )
    async function getData () {
      state.tableLoading = true
      const { data, msg, code } = await marketing.getLevelData()
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function checkTableInput () {
      let hasError = false
      state.editTableData.forEach((item, index) => {
        checkNameInput(undefined, item, index)
        checkAmountInput(undefined, item, index)
        if (item.nameErrorText || item.amountErrorText) {
          hasError = true
        }
      })
      return hasError
    }
    function checkNameInput (e, item, index) {
      if (!item.levelName) {
        root.$set(state.editTableData[index], 'nameErrorText', '请输入等级名称')
      } else if (item.levelName && item.levelName.length > 8) {
        root.$set(state.editTableData[index], 'nameErrorText', '不得超过8个字')
      } else {
        root.$set(state.editTableData[index], 'nameErrorText', '')
      }
    }
    function checkAmountInput (e, item, index) {
      if (index !== 0 && (!item.levelAmount || (item.levelAmount && item.levelAmount <= state.editTableData[index - 1].levelAmount))) {
        root.$set(state.editTableData[index], 'amountErrorText', '请输入佣金下限，且需大于上级的佣金下限')
      } else {
        root.$set(state.editTableData[index], 'amountErrorText', '')
      }
    }
    async function handleSubmit () {
      if (Number(state.levelNum) <= 0) {
        return root.$message.warning('等级总数需大于0')
      }
      if (checkTableInput()) return
      state.modalLoading = true
      const { code, msg } = await marketing.saveLevelData(state.editTableData)
      state.modalLoading = false
      if (code === '00000') {
        state.showModal = false
        root.$message.success('设置成功')
        getData()
      } else {
        root.$message.e(msg || '设置失败')
      }

      console.log(state.editTableData)
    }
    function handleCancel () {}
    function levelSetting () {
      state.showModal = true
      state.levelNum = state.tableData.length
    }
    return {
      ...toRefs(state),
      columns,
      getData,
      handleSubmit,
      handleCancel,
      levelSetting,
      checkTableInput,
      checkNameInput,
      checkAmountInput
    }
  },
}
</script>

<style lang="less" scoped>
.page-box {
  height: calc(100vh - 136px);
  box-sizing: border-box;
  .intro-box {
    margin-bottom: 32px;
    .h1 {
      font-size: 20px;
      font-weight: 900;
      margin-bottom: 4px;
    }
    p {
      font-size: 14px;
      color: #666;
    }
  }
}
.tip {
  font-size: 12px;
  color: red;
  margin-top: 8px;
}
</style>
